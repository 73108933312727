import './App.css';
import React, { Component } from 'react';
import { getPath, getRandomInt, isLocal } from './helpers.js'

const totalNum = 173

function navRandom(disallowedNum) {
  var toGoTo;
  do {
    toGoTo = getRandomInt(totalNum - 1) + 1; // 1 - totalNum
  } while(toGoTo === disallowedNum);
  window.location = isLocal() ? `http://localhost:3000/${toGoTo}` : `https://missingnos.com/${toGoTo}`;
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { cardNum: parseInt(getPath()) || 1 };
  }

  progress = () => {
    const nextCardNum = this.state.cardNum + 1;
    const cardNumToSet = nextCardNum > totalNum ? 1 : nextCardNum;
    window.location.href = cardNumToSet;
    this.setState({ cardNum: cardNumToSet });
  }

  render() {
    const { cardNum } = this.state;
    const actualCardNum = cardNum > totalNum ? 1 : cardNum;
    const imgPath = `https://f.missingnos.com/cards/${actualCardNum}.png`;

    return (
      <div className="app-wrapper">
        <div className="missingno-wrapper">
          <img className="missingno-img" src={imgPath} onClick={this.progress}>
          </img>
          <div className="missingno-text">
            <span>MissingNo. {actualCardNum ?? '?'}</span>
          </div>
          <a className="own-text" href="https://opensea.io/assets/missingno-cards" target="_blank">Own on the blockchain</a>
          <br></br>
          <br></br>
          <span className="random-btn" onClick={() => navRandom(actualCardNum)}>Random</span>
          <span className="current-mint-size-text"> | Current mint size: {totalNum}</span>
        </div>
      </div>
    )
  }
}

export default App;
