export function getPath() {
    return window.location.pathname.replace('/', '')
}

export function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

export function isLocal() {
    return window.location.toString().includes('localhost');
}
